@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  direction: rtl;
}

body {
  background-color: #eceff1;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

::selection{
  background-color: #20b2aa;
  color: #fff;
}
.dashboard-layout {
  display: flex;
}

.sidebar-layout {
  width: 20%;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  padding: 8px 16px;
  border-right: 1px solid #eceff1;
  transition: width 0.3s ease;
  z-index: 5;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -ms-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
}

.sidebar-mobile {
  width: 80px;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  padding: 8px 20px 0px 6px;
  border-left: 1px solid #eceff1;
  transition: width 0.3s ease;
  z-index: 5;
}

.sidebar-mobile .link-pages ul li a p {
  display: none;
}

.content-navbar {
  margin-right: 20%;
  width: 80%;
  transition: margin-right 0.3s ease, width 0.3s ease;
  -webkit-transition: margin-right 0.3s ease, width 0.3s ease;
  -moz-transition: margin-right 0.3s ease, width 0.3s ease;
  -ms-transition: margin-right 0.3s ease, width 0.3s ease;
  -o-transition: margin-right 0.3s ease, width 0.3s ease;
}

.content-navbar-mobile {
    margin-right: 80px;
    width: calc(100% - 80px);
    transition: margin-right 0.3s ease, width 0.3s ease;
}


.navbar-layout {
  background-color: #fff;
  height: 60px;
  padding: 8px 16px;
  border-bottom: 1px solid #eceff1;
}

.content-layout {
  margin: 8px 15px;
  background-color: #fff;
  padding: 11px 8px;
  border-radius: 4px;
  direction: rtl;
}

.sidebar-layout .logo {
  width: 160px;
  height: auto;
  object-fit: cover;
  object-position: center;
  display: block;
  margin: 0 auto;
  user-select: none;
  border-bottom: 1px solid #5555;
  padding-bottom: 20px;
}

.navbar-layout .bar-sidebar {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  width: fit-content;
}

.navbar-layout .bar-sidebar svg {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  color: #5555;
}

.navbar-layout .bar-sidebar:hover > svg {
  color: #3d3d3d;
}

.sidebar-layout .link-pages {
  margin-top: 20px;
  overflow-y: scroll;
  height: calc(100vh - 165px);
}

.sidebar-layout .link-pages ul li a {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 500;
  margin-left: 5px;
  padding: 3px 5px;
  color: #000;
  font-weight: 600;

}

.sidebar-mobile .link-pages ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 500;
}
.sidebar-layout .link-pages ul li,
.sidebar-mobile .link-pages ul li {
  margin-left: 5px;
  text-wrap: nowrap;
  margin-bottom: 10px;
}

.sidebar-layout .link-pages ul .active-link {
  background-color: #20b2aa;
  color: #fff !important;
  border-radius: 7px;
}
.sidebar-layout .link-pages ul .active-link p,
.sidebar-layout .link-pages ul .active-link svg{
  color: #fff !important;
}
.sidebar-mobile .link-pages ul .active-link {
  background-color: #20b2aa;
  color: #fff !important;
  border-radius: 7px;
  padding-top: 12px;
  height: 44px;
}

.sidebar-mobile .link-pages ul li svg {
  width: fit-content;
  font-size: 24px;
  color: #000;
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .content-navbar {
    margin-right: 0;
    width: 100%;
    transition: margin-right 0.3s ease;
  }

  .sidebar-layout {
    max-width: 260px;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    padding: 8px 16px;
    border-right: 1px solid #eceff1;
    transition: width 0.3s ease;
    width: 100%;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -ms-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
}

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0 0 0 / 0.1);
    z-index: 55555;
  }

  .sidebar-title > svg {
    display: flex !important;
  }
  .sidebar-mobile {
    display: none !important;
  }
  .content-navbar-mobile {
    margin-right: 0px !important;
    width: 100%;
    transition: margin-right 0.3s ease;
  }
}
.sidebar-mobile .link-pages {
  margin-top: 20px;
  overflow-y: scroll;
  height: calc(100vh - 70px);
}
.sidebar-title {
  display: flex;
  align-items: flex-start;
}

.sidebar-title > svg {
  font-size: 27px;
  color: #555;
  cursor: pointer;
  display: none;
}

/* Scroll bar */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #eee;
}

::-webkit-scrollbar-thumb:hover {
  background: #eee;
}

/* Table Mui */
.tss-qbo1l6-MUIDataTableToolbar-actions {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: end !important;
}
.tss-iwylj0-MUIDataTableBodyCell-responsiveStackedSmallParent {
  text-align: start !important;
}
.tss-djbknv-MUIDataTablePagination-navContainer {
  display: flex;
  justify-content: flex-start !important;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
  .MuiTablePagination-actions {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 20px;
  direction: ltr;
}
.tss-1832qqn-MUIDataTableHeadRow-root {
  background-color: #20b2aa !important;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  color: #fff !important;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: transparent !important;
  white-space: nowrap !important;
}
.tss-1akey0g-MUIDataTableHeadCell-data {
  display: inline-block;
  font-size: 17px !important;
  font-weight: 600 !important;
}
.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
  color: black !important;
  font-size: 16px !important;
  font-weight: 500;
}
.tss-1opebqo-MUIDataTable-caption {
  position: absolute;
  left: 0;
  display: none !important;
}
.tss-hj53wm-MUIDataTableToolbar-titleText {
  font-size: 22px !important;
  font-weight: 700 !important;
}
.tss-10syd3x-MUIDataTableHeadCell-root {
  padding: 1px !important;
}
.tss-11quiee-MUIDataTable-paper {
  isolation: isolate;
  border-top: 1px solid #eee;
}

/* select */

.css-13cymwt-control,
.css-t3ipsp-control,
.css-3w2yfm-ValueContainer {
  direction: ltr !important;
  text-align: end !important;
}

.css-1nmdiq5-menu {
  direction: ltr !important;
  text-align: end !important;
  height: 150px;
  overflow-x: scroll;
}
.css-11mde6h-MuiPaper-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 0px !important;
}

.headlessui-dialog-panel {
  width: 100%;
  max-width: 650px;
  border-radius: 8px;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  padding: 12px !important;
  text-align: start !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}
.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.ant-pagination-next,
.ant-pagination-prev {
  transform: rotate(180deg);
}

.ant-pagination {
  margin-top: 20px !important;
}
.ant-pagination-item-link{
  font-size: 16px !important;
  /* color: #000 !important; */
}
.ant-pagination-item-active{
  font-weight: 600 !important;
  border-color: #20b2aa !important;
  background-color: #20b2aa !important;
  color: #fff !important;
}
.ant-pagination-item-active>a{
  color: #fff !important;
}
.tss-iwylj0-MUIDataTableBodyCell-responsiveStackedSmallParent {
  white-space: nowrap !important;
}


#date-range{
  background-color: #fff !important;
  padding: 8px 0px !important;
  border-radius: .5rem !important;
  color: #000 !important;
  direction: ltr !important;
  -webkit-border-radius: .5rem !important;
  -moz-border-radius: .5rem !important;
  -ms-border-radius: .5rem !important;
  -o-border-radius: .5rem !important;
  width: 92%;
  text-align: center;
}

#date-range::placeholder{
  direction: rtl !important;
}

.btn-cancel-del{
  height: 100% !important;
  width: 8% !important;
  font-size: 24px !important;
  min-width: 40px !important;
}